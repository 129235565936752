import './App.css';
import React, { useState, useEffect } from 'react';
import { SURVEY_TYPE } from './Utils/Constants';
import { fetchApi, getPathVariable, getQueryVariable } from './Utils/Fetch';
import LoroSurvey from './Components/LoroSurvey';
import PageLoader from './Components/PageLoader';
import NavBar from './Components/NavBar';
import './i18n';

function App() {
  console.log("Init App");
  let surveyType = getPathVariable();
  let d = getQueryVariable("debug");
  const [surveyJson, setSurveyJson] = useState(null);
  const [debug, setDebug] = useState(false);

  useEffect(() => {
    setDebug(d === "true");
  }, [d, setDebug]);

  useEffect(() => {
    if (Object.values(SURVEY_TYPE).includes(surveyType)) {
      fetchApi(`/api/get-survey-data/${surveyType}`, { method: 'GET' }).then((json) => {
        setSurveyJson(json);
      });
    }
  }, [surveyType, setSurveyJson]);

  return (
    <div className="App">
      <NavBar></NavBar>
      {surveyJson ? <LoroSurvey debug={debug} surveyJson={surveyJson} surveyType={surveyType}></LoroSurvey> : <PageLoader></PageLoader>}
    </div>
  );
}

export default App;
