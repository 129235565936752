import { API_KEY } from './Constants';

export const getPathVariable = () => {
    var path = window.location.pathname;
    let param = path.replace('/', '');
    return param;
};

export const getQueryVariable = (key) => {
    const queryParams = new URLSearchParams(window.location.search)
    const value = queryParams.get(key)
    return value;
};

const fetchJson = async (url, options) => {
    var response = await fetch(url, options);
    var obj = await response.json();
    if (response.ok)
        return obj;
    else
        throw obj;
}

export const fetchApi = async (url, options) => {
    options = options || {};
    options.headers = options.headers || new Headers();
    options.query = Object.assign({ key: API_KEY }, options.query)
    if (options.query) {
        Object.keys(options.query).forEach(key => options.query[key] === undefined ? delete options.query[key] : {});
        var querystring = new URLSearchParams(options.query).toString()
        url = `${url}?${querystring}`;
    }
    delete options.query;
    if (options.body) {
        options.body = JSON.stringify(options.body);
        options.headers.append('Accept', 'application/json');
        options.headers.append('Content-Type', 'application/json');
    }
    // handle here managed errors
    var obj = await fetchJson(url, options);
    return obj;
}