import React from 'react';
import { useTranslation } from 'react-i18next';

function NavBar() {
    console.log("Init NavBar");
    const { i18n } = useTranslation();

    const changeLanguage = (lan) => {
        i18n.changeLanguage(lan);
    }

    return (
        <div className="d-flex justify-content-end m-2">
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownLanguageButton" data-bs-toggle="dropdown" aria-expanded="false">
                    Language
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownLanguageButton">
                    <li><div className="dropdown-item" onClick={() => changeLanguage("en")}>English</div></li>
                    <li><div className="dropdown-item" onClick={() => changeLanguage("ko-KR")}>Korean</div></li>
                    <li><div className="dropdown-item" onClick={() => changeLanguage("zh-CN")}>Cinese Semplificato</div></li>
                    <li><div className="dropdown-item" onClick={() => changeLanguage("zh-TW")}>Cinese Tradizionale</div></li>
                    <li><div className="dropdown-item" onClick={() => changeLanguage("ja-JP")}>Japanese</div></li>
                </ul>
            </div>
        </div>
    );
}

export default NavBar;