import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            "en": {
                translation: {
                    "peat": "Result: {result}",
                    "six-human-needs": "Result: {result1} and {result2}",
                    "sensory-styles": "Result: {result}"
                }
            },
            "ko-KR": {
                translation: {
                    "peat": "결과: {result}",
                    "six-human-needs": "결과: {result1} 그리고 {result2}",
                    "sensory-styles": "결과: {result}"
                }
            },
            "zh-CN": {
                translation: {
                    "peat": "结果: {result}",
                    "six-human-needs": "结果: {result1} 和 {result2}",
                    "sensory-styles": "结果: {result}"
                }
            },
            "zh-TW": {
                translation: {
                    "peat": "結果: {result}",
                    "six-human-needs": "結果: {result1} 和 {result2}",
                    "sensory-styles": "結果: {result}"
                }
            },
            "ja-JP": {
                translation: {
                    "peat": "結果: {result}",
                    "six-human-needs": "結果: {result1} その後 {result2}",
                    "sensory-styles": "結果: {result}"
                }
            }
        }
    });

export default i18n;