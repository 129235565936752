import { SURVEY_TYPE } from './Constants';

export const parseSurveyResult = (surveyType, baseResult, value) => {
  let result = null;
  switch (surveyType) {
    case SURVEY_TYPE.PEAT:
    case SURVEY_TYPE.SENSORY_STYLES:
      result = baseResult.replace("{result}", value);
      break;
    case SURVEY_TYPE.SIX_HUMAN_NEEDS:
      let splitted = value.split(",");
      result = baseResult.replace("{result1}", splitted[0]).replace("{result2}", splitted[1]);
      break;
    default:
      throw Error("Unknown survey type");
  }
  return result;
}

export const parseSurvey = (surveyType, senderData) => {
  let parsed = null;
  switch (surveyType) {
    case SURVEY_TYPE.PEAT:
    case SURVEY_TYPE.SENSORY_STYLES:
      parsed = parseSimple(senderData);
      break;
    case SURVEY_TYPE.SIX_HUMAN_NEEDS:
      parsed = parseTable(senderData);
      break;
    default:
      throw Error("Unknown survey type");
  }
  return { items: parsed };
}

function parseSimple(data) {
  let rows = [];
  for (const [key, value] of Object.entries(data)) {
    let row = { "question_code": key, "answer": value };
    rows.push(row);
  }
  return rows;
}

function parseTable(data) {
  let rows = [];
  for (const value of Object.values(data)) {
    for (const [rowKey, rowValue] of Object.entries(value)) {
      let row = { "question_code": rowKey, "answer": rowValue };
      rows.push(row);
    }
  }
  return rows;
}