import React, { useState, useEffect, useCallback } from 'react';
import { fetchApi } from '../Utils/Fetch';
import { parseSurvey } from '../Utils/AnswerParser';
import { useTranslation } from 'react-i18next';
import SurveyResult from './SurveyResult';
import 'survey-core/defaultV2.min.css';
import { Model, StylesManager, surveyLocalization } from 'survey-core';
import { Survey } from 'survey-react-ui';
import "survey-core/survey.i18n";

StylesManager.applyTheme("defaultV2");

function LoroSurvey(props) {
    console.log("Init LoroSurvey");
    surveyLocalization.locales["ko-KR"] = surveyLocalization.locales["ko"];
    surveyLocalization.locales["zh-TW"] = surveyLocalization.locales["zh-tw"];
    surveyLocalization.locales["zh-CN"] = surveyLocalization.locales["zh-cn"];
    surveyLocalization.locales["zh-TW"]["booleanCheckedLabel"] = '是'
    surveyLocalization.locales["zh-TW"]["booleanUncheckedLabel"] = '否'

    surveyLocalization.locales["ja-JP"] = {};
    surveyLocalization.locales["ja-JP"]["booleanCheckedLabel"] = 'はい';
    surveyLocalization.locales["ja-JP"]["booleanUncheckedLabel"] = 'いいえ';
    surveyLocalization.locales["ja-JP"]["pagePrevText"] = '前';
    surveyLocalization.locales["ja-JP"]["pageNextText"] = '続く';
    surveyLocalization.locales["ja-JP"]["requiredError"] = '必須フィールド';
    surveyLocalization.locales["ja-JP"]["requiredInAllRowsError"] = 'すべての行を完了する';
    surveyLocalization.locales["ja-JP"]["completeText"] = '保存';


    const surveyType = props.surveyType;
    const surveyJson = props.surveyJson;
    const debug = props.debug;
    const [localeReady, setLocaleReady] = useState(false);

    const { i18n } = useTranslation();
    const [surveyModel, setSurveyModel] = useState(null);
    const [surveyCompleted, setSurveyCompleted] = useState(false);
    const [surveyMessageResult, setSurveyMessageResult] = useState(null);

    const surveyComplete = useCallback(async (sender) => {
        var parsed = parseSurvey(surveyType, sender.data);
        const response = await fetchApi(`/api/${surveyType}`, { method: 'POST', body: parsed });
        setSurveyMessageResult(response.result);
    }, [surveyType]);

    const onCompleting = useCallback(async (sender) => {
        setSurveyCompleted(true);
    }, [setSurveyCompleted]);

    useEffect(() => {
        if (debug) {
            for (var i = 0; i < surveyJson.pages.length; i++) {
                for (var j = 0; j < surveyJson.pages[i].elements.length; j++) {
                    surveyJson.pages[i].elements[j].isRequired = false;
                    surveyJson.pages[i].elements[j].isAllRowRequired = false;
                }
            }
        }

        let model = new Model(surveyJson);
        fixBooleanLayout(model);
        model.onComplete.add(surveyComplete);
        model.onCompleting.add(onCompleting);
        setSurveyModel(model);
    }, [surveyType, surveyJson, surveyComplete, onCompleting]);

    const fixBooleanLayout = (model) => {
        if (model) {
            for (var i = 0; i < model.pages.length; i++) {
                for (var j = 0; j < model.pages[i].elements.length; j++) {
                    try {
                        if (model.pages[i].elements[j].getType() === "boolean") {
                            model.pages[i].elements[j].__proto__.getCompactRenderAs = () => "switch";
                        }
                    }
                    catch (err) {
                        console.error(err);
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (surveyModel) {
            surveyModel.locale = i18n.language;
        }
        setLocaleReady(true);
    }, [surveyModel, i18n.language]);

    return (
        <>
            {surveyModel && localeReady && <Survey model={surveyModel} />}
            {surveyCompleted && <SurveyResult surveyType={surveyType} surveyMessageResult={surveyMessageResult}></SurveyResult>}
        </>
    );
}

export default LoroSurvey;