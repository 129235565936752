import React from 'react';
import { parseSurveyResult } from '../Utils/AnswerParser';
import { useTranslation } from 'react-i18next';

function SurveyResult(props) {
    console.log("Init SurveyResult");
    const { t } = useTranslation();
    let result = null;
    if (props.surveyMessageResult) {
        let baseResult = t(props.surveyType);
        result = parseSurveyResult(props.surveyType, baseResult, props.surveyMessageResult);
    }


    return (
        <div className='survey-result'>
            {!props.surveyMessageResult ? <div className="panel">
                <div className="col">
                    <div className="pulse-base pulse-circle"></div>
                </div>
            </div> : <h3>{result}</h3>}
        </div>
    );
}

export default SurveyResult;