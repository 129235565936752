import React from 'react';

function PageLoader() {
    console.log("Init PageLoader");

    return (
        <div className="loader-container my-5">
            <img className='rotate' src='/assets/brand/Loro-Piana_Logo-174U_2.png' width="200" height="180" alt="logo"></img>
        </div>
    );
}

export default PageLoader;